import React from 'react';
 
const About = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'Left',
        alignItems: 'Right',
        height: '100vh'
      }}
    >
      <h1>--About--</h1>
      <br/><br/>
      {/* <img src='/images/ims.jpg' width='300px' height='200px' /> */}
    </div>
  );
};
 
export default About;