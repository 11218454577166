import React from 'react';
 
const Home = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'Center',
        alignItems: 'Right',
        height: '100vh'
      }}
    >
      <h1>--Home--</h1>
    </div>
  );
};
 
export default Home;
